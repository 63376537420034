
import axios from 'axios'
import SimpleDateTime from 'react-simple-timestamp-to-date';


const url = "https://api.archivum.be:3001"

export async function getDocument(cat, order, orderType) {

    var doc = {
        orga: 1,
        cat: cat
    }

    const res = await axios.post(url + '/api/getDocF', doc);


    for (var i = 0; i < res.data.length; i++) {

        var dateTimeProperties = { 'dateSeparator': '/', 'dateFormat': 'DMY', 'showTime': '0', 'children': res.data[i].d_creation }

        res.data[i].d_creation = SimpleDateTime(dateTimeProperties);

       // console.log(res.data[i].lieu)


        if( res.data[i].lieu == null)
        {
            res.data[i].lieu = 'Inconnu'
        }

    }



    return res.data;

}

export async function getAllDocument(order, orderType) {

    var doc = {
        orga: 1,
    }

    const res = await axios.post(url + '/api/getDocFAll', doc);


    for (var i = 0; i < res.data.length; i++) {

        var dateTimeProperties = { 'dateSeparator': '/', 'dateFormat': 'DMY', 'showTime': '0', 'children': res.data[i].d_creation }

        res.data[i].d_creation = SimpleDateTime(dateTimeProperties);

       // console.log(res.data[i].lieu)


        if( res.data[i].lieu == null)
        {
            res.data[i].lieu = 'Inconnu'
        }

    }



    return res.data;

}

export async function searchDocument(text) {


    console.log('oktest');
    const res = await axios.get(url + '/api/searchDoc/1/'+text);


    for (var i = 0; i < res.data.length; i++) {

        var dateTimeProperties = { 'dateSeparator': '/', 'dateFormat': 'DMY', 'showTime': '0', 'children': res.data[i].d_creation }

        res.data[i].d_creation = SimpleDateTime(dateTimeProperties);

       // console.log(res.data[i].lieu)


        if( res.data[i].lieu == null)
        {
            res.data[i].lieu = 'Inconnu'
        }

    }



    return res.data;

}


export async function getCategorie() {

   
  
    const res = await axios.get(url + '/api/getCat/' + 1);



    return res.data;

}

export async function getChildCategorie(parent) {

   
    const res = await axios.get(url + '/api/getChildCat/' + 1 +'/'+ parent);


    return res.data;

}



export async function getDocId(id) {

   
  
    const res = await axios.get(url + '/api/getDocId/' + id);



    return res.data;

}


export async function getDocLink(id) {


  
    const res = await axios.get(url + '/api/getDocLink/' + id);



    return res.data;

}