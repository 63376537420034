import React, { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import './css/style.css'
import pres1 from './img/baneer1.png'
import pres2 from './img/we-are.png'
import pres3 from './img/pub.png'
import ArchNav from './arch-nav';
import Item from './item'
import LandingCard from './landingCard'
import LandingSmallCard from './landingSmallCard'
import { getDocument, getCategorie, getAllDocument, getChildCategorie } from './request'
import Select from 'react-select'
import { Placeholder } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

import './index.css'



const Acceuil = () => {
    const [selectedOption, setSelectedOption] = useState('option1');
    const [selectedOptionCat, setSelectedOptionCat] = useState(1);
    const [docList, setDocList] = useState([]);
    const [catList, setCatList] = useState([]);
    const [catId, setcatId] = useState(1);
    const [isOpen, setIsOpen] = useState(false);

    const options = [
        { value: 'chocolate', label: 'Date descendant' },
        { value: 'strawberry', label: 'Date Ascendant' },
    ]

    const options2 = [
        { value: 'chocolate', label: 'Fumal' },
        { value: 'strawberry', label: 'Braives' },
        { value: 'strawberry', label: 'Latinne' },
        { value: 'strawberry', label: 'Waleffe' },
    ]




    useEffect(() => {

        const id = localStorage.getItem('catid');

        if (id) {
            console.log("ok");
            setcatId(id);
            setSelectedOptionCat(id);
        }


        async function func(id) {
            const d = await getCategorie();

            for (var i = 0; i < d.length; i++) {
                const c = await getChildCategorie(d[i].Id_t_categorie);
                var dd = d[i];

                console.log(c);

                dd.isOpen = false;
                dd.catChild = c;

                d[i] = dd;
            }

            //  console.log(d);

            d.push({ d_id_name: 'Tout', Id_t_categorie: 99, d_img: 'Tout.JPG' })

            console.log(d);



            setCatList(d);
            updtDoc(id);
        }
        // setData(f.values);

        func(id);




    }, []);

    const toggleDropdown = (id) => {
        setIsOpen(!isOpen);

        var dd = catList[id];
        var isop = dd.isOpen;
        dd.isOpen = !isop;

        console.log(isop);

        catList[id] = dd;


    };


    function handleChange(event) {
        setSelectedOption(event.target.value);

    }

    function handleChangeCat(event) {
        setSelectedOptionCat(event.target.value);

        //   console.log(event.target.value);


        updtDoc(event.target.value);


        localStorage.setItem('catid', event.target.value);
    }

    const updtDoc = async (id) => {

        var list = {};



        if (id != 99) {
            list = await getDocument(id, 'desc', 'date');
        }
        else {
            list = await getAllDocument('desc', 'date');
        }

        setDocList(list);



        // console.log(list)


    };



    return (
        <div>


            <section>



                <div className="landing-slider">
                    <div className="landing-slider-text">
                        <h1>Bienvenue sur Histoire Braives</h1>
                        <p>Histoire Braives est une bibliothèque virtuelle qui permet de sauvegarder et de consulter les archives relatives à l’histoire des huit villages de l’entité. L’histoire avec un grand H, mais aussi la petite histoire.</p>
                        <p> Le but est de conserver la mémoire de nos villages à travers tous les types de documents : écrits, photos, vidéos, VHS, Super 8.</p>
                    </div>
                </div>




                <div className="title">
                    <h2>Les villages</h2>
                    <p>Vous pouvez sélectionner un village pour consulter ses données.</p>
                </div>


                <div className="landing-card-container">


                    <LandingSmallCard key={1} title={"Braives"} id={18} image={process.env.PUBLIC_URL + "/" + "braives.jpg"} />
                    <LandingSmallCard key={1} title={"Brivioulle"} id={15} image={process.env.PUBLIC_URL + "/" + "brivioulle.jpeg"} />
                    <LandingSmallCard key={1} title={"Latinne"} id={11} image={process.env.PUBLIC_URL + "/" + "Latinne.jpg"} />
                    <LandingSmallCard key={1} title={"Tourinne"} id={12} image={process.env.PUBLIC_URL + "/" + "Tourinne.jpg"} />
                    <LandingSmallCard key={1} title={"Ville-en-Hesbaye"} id={13} image={process.env.PUBLIC_URL + "/" + "Ville-en-Hesbaye.jpg"} />
                    <LandingSmallCard key={1} title={"Hosdent"} id={14} image={process.env.PUBLIC_URL + "/" + "Hosdent.jpg"} />
                    <LandingSmallCard key={1} title={"Pitet"} id={16} image={process.env.PUBLIC_URL + "/" + "Pitet.jpg"} />
                    <LandingSmallCard key={1} title={"Avennes"} id={17} image={process.env.PUBLIC_URL + "/" + "Avennes.jpg"} />
                    <LandingSmallCard key={1} title={"Ciplet"} id={19} image={process.env.PUBLIC_URL + "/" + "Ciplet.jpg"} />
                    <LandingSmallCard key={1} title={"Fallais"} id={20} image={process.env.PUBLIC_URL + "/" + "Fallais.jpg"} />
                    <LandingSmallCard key={1} title={"Fumal"} id={21} image={process.env.PUBLIC_URL + "/" + "Fumal.jpg"} />
                </div>



                <div className="title">
                    <h2>Les catégories</h2>
                    <p>Vous pouvez sélectionner une catégorie pour consulter toutes les données présentes dans cette catégorie.</p>
                </div>


                <div className="landing-card-container">


                    {
                        catList.map((item, i) => (
                            <div>
                                {
                                    item.Id_t_categorie < 99 ? <LandingCard key={i} title={item.d_id_name} id={1000 - i} text={item.d_description} image={process.env.PUBLIC_URL + "/" + item.d_img} />
                                        : <LandingCard key={i} title={item.d_id_name} id={99} text={item.d_description} image={process.env.PUBLIC_URL + "/" + item.d_img} />
                                }

                            </div>



                        ))
                    }





                </div>
            </section>

        </div>
    );
}

export default Acceuil;
