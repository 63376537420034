import React, { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import './css/style.css'
import pres1 from './img/baneer1.png'
import pres2 from './img/we-are.png'
import pres3 from './img/pub.png'
import ArchNav from './arch-nav';
import Item from './item'
import { getDocument, getCategorie, getAllDocument, getChildCategorie, searchDocument } from './request'
import Select from 'react-select'
import { Placeholder } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'





const Archives = () => {
    const [selectedOption, setSelectedOption] = useState('option1');
    const [selectedOptionCat, setSelectedOptionCat] = useState(1);
    const [docList, setDocList] = useState([]);
    const [docListFiltered, setDocListFiltered] = useState([]);
    const [catList, setCatList] = useState([]);
    const [catId, setcatId] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [villageList, setVillageList] = useState([]);
    const [selVillage, setSelVillage] = useState(null);
    const [forceRender, setForceRender] = useState(false);
    const [searchText, setSearchText] = useState('');


    const options = [
        { value: 1, label: "Date d'ajout du document ascendant" },
        { value: 2, label: "Date d'ajout du document descendant" },
        { value: 3, label: 'Date du document ascendant' },
        { value: 4, label: "Date du document descendant" }
    ]






    useEffect(() => {

        const id = localStorage.getItem('catid');

        if (id) {
            console.log("ok");
            setcatId(id);
            setSelectedOptionCat(id);
        }


        async function func(id) {
            const d = await getCategorie();
            const v = await getChildCategorie(2);

            console.log(v);

            for (var i = 0; i < d.length; i++) {
                const c = await getChildCategorie(d[i].Id_t_categorie);

                var cc = c;
                cc.push({ d_id_name: 'Tout', Id_t_categorie: d[i].Id_t_categorie });



                var dd = d[i];

                console.log(c);


                dd.isOpen = false;

                for (var o = 0; o < c.length; o++) {
                    if (id == c[o].Id_t_categorie) {
                        dd.isOpen = true;
                    }
                }





                dd.catChild = cc;

                d[i] = dd;





            }


            var h = [];


            for (var i = 0; i < v.length; i++) {

                h.push({ key: i, value: v[i].Id_t_categorie, label: v[i].d_id_name });



            }

            setVillageList(h)


            //  console.log(d);

            d.push({ d_id_name: 'Tout', Id_t_categorie: 99 })

            console.log(d);



            setCatList(d);
            updtDoc(id);

        }
        // setData(f.values);

        func(id);




    }, []);

    const toggleDropdown = (id) => {
        setIsOpen(!isOpen);

        var dd = catList[id];
        var isop = dd.isOpen;
        dd.isOpen = !isop;

        console.log(isop);

        catList[id] = dd;


    };

    function convertirEnDate(dateString) {
        const [jour, mois, annee] = dateString.split('/');
        return new Date(`${annee}-${mois}-${jour}`);
    }



    function handleOrderList(event) {

        var f = docListFiltered.slice();

        if (event) {

            switch (event.value) {

                case 1: // Date d'ajout du document ascendant
                    f.sort((a, b) => convertirEnDate(a.d_creation) - convertirEnDate(b.d_creation));
                    console.log("coucou 1");
                    break;
                case 2: // Date d'ajout du document descendant
                    f.sort((a, b) => convertirEnDate(b.d_creation) - convertirEnDate(a.d_creation));
                    console.log("coucou 2");
                    break;
                case 3: // Date du document ascendant
                    f.sort((a, b) => a.d_year - b.d_year);
                    console.log("coucou 3");
                    break;
                case 4: // Date du document descendant
                    f.sort((a, b) => b.d_year - a.d_year);
                    console.log("coucou 4");
                    break;
            }



        }
        else {
            f.sort((a, b) => convertirEnDate(b.d_creation) - convertirEnDate(a.d_creation));
        }




        setDocListFiltered(f);


    }


    function handleChangeVillage(event) {

        console.log(event)
        if (event) {
            setSelVillage(event.label);
            updateVillage(docList, event.label);
        }
        else {
            setSelVillage(null);
            updateVillage(docList, null);
        }




    }

    function updateVillage(plist, village) {

        if (village) {


            var f = [];


            for (var i = 0; i < plist.length; i++) {


                if (plist[i].lieu === village) {
                    f.push(plist[i])
                }
                else {

                }
            }

            setDocListFiltered(f);

            console.log(f.length);




        }
        else {
            console.log("dedans");
            setDocListFiltered(plist);
        }


    }


    function handleChange(event) {


        setSelectedOption(event.target.value);

    }

    function handleChangeCat(event) {
        setSelectedOptionCat(event.target.value);

        console.log(event.target.value);


        updtDoc(event.target.value);


        localStorage.setItem('catid', event.target.value);
    }

    const updtDoc = async (id) => {

        var list = {};


        console.log(id)

        if (id != 99) {
            list = await getDocument(id, 'desc', 'date');
        }
        else {
            list = await getAllDocument('desc', 'date');
        }

        setDocList(list);

        console.log(selVillage);

        if (!selVillage) {
            setDocListFiltered(list);
        }
        else {
            updateVillage(list, selVillage);
        }


    };



    const handleChangeS = (e) => {
        setSearchText(e.target.value);
    };

    const handleSearchTag = (e) => {

        if (searchText) {
            const dataFetch = async () => {

                console.log('ok');
                var list = {};
                list = await searchDocument(searchText);

                //setDataFileFiltered(f);
                setDocListFiltered(list);
            }

            dataFetch();
        }
        else {
            updtDoc(99);
        }
    };




    return (
        <div>


            <section>

                <div className="breadcrumb-section">
                    <div className="breadcrumb-content">

                        <div className="breadcrumb-element">
                            <h2>Archives</h2>
                            <p>Vous pouvez explorer et parcourir la liste complète des différentes archives et documents disponibles. Vous avez un accès facile à une vaste collection de documents et de ressources numériques.</p>
                        </div>
                        <div className="breadcrumb-element">
                            <Link to={"/accueil/"}>
                                <p>Retour</p>
                            </Link>

                        </div>

                    </div>
                </div>



                <div className="arch-section">





                    <div className="arch-nav-menu">



                        <div className="arch-menu-catCheck">
                            {
                                catList.map((item, i) => (

                                    <div className="catItem" key={i}>

                                        {item.isOpen ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretUp} />}

                                        <a href="#!" onClick={() => toggleDropdown(i)}>{item.d_id_name}

                                        </a>

                                        {
                                            catList[i].isOpen && (
                                                Array.isArray(item.catChild) ? (
                                                    item.catChild.map((itemC, d) => (
                                                        <div className="childItem" key={d}>
                                                            <label className="container" key={d}>{itemC.d_id_name}
                                                                <input type="radio" key={d} value={itemC.Id_t_categorie} checked={selectedOptionCat == itemC.Id_t_categorie} onChange={handleChangeCat} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="childItem" key={99}>
                                                        <label className="container" key={99}>{"Tout"}
                                                            <input type="radio" key={99} value={99} checked={selectedOptionCat == 99} onChange={handleChangeCat} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                )
                                            )
                                        }




                                    </div>

                                ))
                            }
                        </div>

                    </div>

                    <div className="arch-content">

                        <div className="arch-content-nav">





                            <div className="arch-content-nav-select1">
                                <Select options={villageList} placeholder={"Lieu"} isClearable={true} onChange={handleChangeVillage} />
                            </div>
                            <div className="arch-content-nav-select2">
                                <Select options={options} placeholder={"Trier par date"} onChange={handleOrderList} isClearable={true} />

                            </div>

                            <div className="arch-content-nav-search">
                                <input className="form-control" value={searchText} id="exampleFormControlInput1" type="text" placeholder="Rechercher" onChange={handleChangeS} />
                                <span onClick={() => handleSearchTag()} className='btn btn-info'><i className="fa fa-search"></i></span>
                            </div>


                        </div>


                        <div className="arch-item-list">
                            {
                                docListFiltered.map(function (item, i) {
                                    return (

                                        <Item key={i} title={item.d_name} id={item.Id_t_document} date={item.d_creation} year={item.d_year} localisation={item.lieu} description={item.d_text} path={item.d_thumb} />
                                    )
                                })
                            }


                        </div>




                    </div>
                </div>
            </section>

        </div>
    );
}

export default Archives;
