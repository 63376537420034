import React from 'react'
import './css/style.css'
import bct from './img/bct.png'
import pp from './img/pp.png'
import visa from './img/visa.png'

import disc from './img/discord.png'
import twit from './img/twitter.png'
import face from './img/facebook.png'

import mail from './img/mail.png'
import call from './img/call.png'


const Footer = () => (
    <footer>
        <a href="./propos">Mentions légales</a>
        <p>© Copyright réalisé par HRPC Informatique</p>
    </footer>
)

export default Footer
