import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useMatch,
  useParams
} from "react-router-dom";

import './App.css';
import Header from './header';
import Acceuil from './accueil';
import Archives from './archives';
import Document from './document';
import Propos from './propos';
import axios from 'axios';
import Footer from './footer'
import './css/style.css'
import { Helmet } from 'react-helmet'
import chercher from './img/chercher.png'
import logo from './img/braives.png'

import 'bootstrap/dist/css/bootstrap.min.css';





function App() {





  return (
    <div>
      <Helmet>
        <title>Histoire Braives - Bibliothèue virtuelle</title>
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Oswald:wght@300;400;700&family=Roboto&display=swap&family=Montserrat:ital,wght@0,300;0,700;1,300;1,700&display=swap" rel="stylesheet"></link>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
          integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM"
          crossorigin="anonymous"
        />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
      </Helmet>
      <Router>
        <div>
          <header>
            <div className="logo">
              <a href="http://histoire-braives.be"><img src={logo} alt="Logo" /></a>
            </div>

            <div className="menu">
              <nav>
                <ul>
                  
                  <li>
                    <Link to="/accueil">Accueil</Link>
                  </li>
                  <li>
                    <Link to="/archives">Archives</Link>
                  </li>
                  <li>
                    <Link to="/a-propos">A propos</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Contact</Link>
                  </li>
                </ul>
              </nav>
            </div>

          </header>


          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Routes>
            <Route path={`${process.env.PUBLIC_URL}`} element={<Acceuil />} />
            <Route path={`${process.env.PUBLIC_URL}/archives`} element={<Archives />} />
            <Route path={`${process.env.PUBLIC_URL}/contact`} />
            <Route path={`${process.env.PUBLIC_URL}/a-propos`} element={<Propos />}/>
            <Route path={`${process.env.PUBLIC_URL}/accueil`} element={<Acceuil />} />
            <Route path={`${process.env.PUBLIC_URL}/document/:id`} element={<Document />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );

}





export default App;
