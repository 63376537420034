import React from 'react'
import './css/style.css'

import { Link } from 'react-router-dom';




const Item = (props) => {


    return (
        <div>
            <div className="item">
                <div className="item-title">
                    <Link to={"/document/" + props.id}>
                        <h3>{props.title}</h3>
                    </Link>
                </div>
                <div className="item-content">
                    <div className="item-img">
                        <img src={"https://arch1612.blob.core.windows.net/data/" + props.path} alt="Logo" />
                    </div>


                    <div className="item-description">
                        <p>{props.description}</p>

                    </div>

                    <div className="item-info">
                        <div className="item-detail-group">
                            <div className="item-detail-label">
                                <p>Date d'ajout :</p>
                            </div>
                            <div className="item-detail-text">
                                <p>{props.date}</p>
                            </div>
                        </div>

                        <div className="item-detail-group">
                            <div className="item-detail-label">
                                <p>Année :</p>
                            </div>
                            <div className="item-detail-text">
                            <p>{props.year}</p>
                            </div>
                        </div>
                        <div className="item-detail-group">
                            <div className="item-detail-label">
                                <p>Lieu :</p>
                            </div>
                            <div className="item-detail-text">
                                <p>{props.localisation}</p>
                            </div>
                        </div>
                    </div>
                </div >
                <hr />
            </div >
        </div >

    )
};

export default Item
