import React, { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import './css/style.css'
import pres1 from './img/baneer1.png'
import pres2 from './img/we-are.png'
import pres3 from './img/pub.png'
import ArchNav from './arch-nav';
import Item from './item'
import LandingCard from './landingCard'
import LandingSmallCard from './landingSmallCard'
import { getDocument, getCategorie, getAllDocument, getChildCategorie } from './request'
import Select from 'react-select'
import { Placeholder } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

import './index.css'



const Propos = () => {




    useEffect(() => {

       


    }, []);

   



    return (
        <div>


            <section>

            <div className="breadcrumb-section">
                    <div className="breadcrumb-content">

                        <div className="breadcrumb-element">
                            <h2>A propos</h2>
                        </div>
                        <div className="breadcrumb-element">
                            <Link to={"/accueil/"}>
                                <p>Retour</p>
                            </Link>

                        </div>

                    </div>
                </div>

             


            <div className="propo-text">
              
               <p>L'Histoire Braive est une initiative exceptionnelle qui a pour mission de préserver, valoriser et partager le patrimoine historique et culturel de la commune de Braives. Cette bibliothèque virtuelle, accessible à tous, rassemble une riche collection de documents, archives et témoignages qui retracent l'évolution de la commune à travers les siècles.</p>

<p>Conçue pour être un lieu de mémoire vivant, l'Histoire Braive permet aux habitants, chercheurs, historiens et curieux de découvrir des trésors insoupçonnés du passé de Braives. Qu'il s'agisse de vieilles photographies, de registres anciens, de cartes postales, de journaux d'époque ou de récits oraux, chaque document numérisé contribue à raconter l'histoire unique de cette commune.</p>

<p>Cette plateforme offre une interface intuitive où les utilisateurs peuvent parcourir les archives, rechercher des informations spécifiques ou même contribuer en partageant leurs propres documents et souvenirs. En réunissant ces ressources en un seul lieu virtuel, l'Histoire Braive favorise la transmission intergénérationnelle et permet de maintenir vivante la mémoire collective de la communauté.</p>

<p>L'Histoire Braive est bien plus qu'une simple collection d'archives; c'est un pont entre le passé et le présent, un outil essentiel pour comprendre les racines de la commune et un témoignage de l'attachement des habitants à leur histoire. Grâce à cette bibliothèque virtuelle, le patrimoine de Braives est préservé pour les générations futures, offrant à chacun l'opportunité de se reconnecter avec l'histoire de sa commune, de l'enrichir et de la partager avec le monde entier.</p>
</div>
               


                
            </section>

        </div>
    );
}

export default Propos;
