import React, { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import './css/style.css'
import pres1 from './img/baneer1.png'
import pres2 from './img/we-are.png'
import pres3 from './img/pub.png'
import ArchNav from './arch-nav';
import Item from './item'
import { getDocId, getDocLink } from './request'
import { useParams, Link } from 'react-router-dom';
import ImageGallery from "react-image-gallery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faDownload } from '@fortawesome/free-solid-svg-icons'





const Document = () => {

    const { id } = useParams();
    const [doc, setDoc] = useState([{ d_name: "", d_text: "" }]);
    const [files, setFiles] = useState([]);
    const [ifImage, setifImage] = useState(false);
    const [ifPdf, setifPdf] = useState(false);

    const [images, setImages] = useState([
        {
            original: "https://picsum.photos/id/1018/1000/600/",
            thumbnail: "https://picsum.photos/id/1018/250/150/",
        },
        {
            original: "https://picsum.photos/id/1015/1000/600/",
            thumbnail: "https://picsum.photos/id/1015/250/150/",
        },
        {
            original: "https://picsum.photos/id/1019/1000/600/",
            thumbnail: "https://picsum.photos/id/1019/250/150/",
        },
    ]);

    useEffect(() => {


        async function func() {
            const d = await getDocId(id);

            const f = await getDocLink(id);

            console.log(d);
            console.log(f);
            setDoc(d);

           

            var img = [];
            var fil = [];


            for (var i = 0; i < f.length; i++) {
                if (f[i].d_type == "jpg") {
                    setifImage(true);
                    img.push({ original: "https://arch1612.blob.core.windows.net/data/" + f[i].d_path, thumbnail: "https://arch1612.blob.core.windows.net/data/" + f[i].d_path , description: f[i].d_description })



                }
                else if (f[i].d_type == "pdf") {
                    setifPdf(true);
                    fil.push(f[i]);
                }
                else
                {
                    setifImage(true);
                    img.push({ original: "https://arch1612.blob.core.windows.net/data/" + f[i].d_path, thumbnail: "https://arch1612.blob.core.windows.net/data/" + f[i].d_path, description: f[i].d_description })
                }
            }

            setImages(img);
            setFiles(fil);


        }
        // setData(f.values);

        func();




    }, []);


    const handleGoBack = () => {
        window.history.back();
    };



    return (
        <div>
            <section>
                <div className="breadcrumb-section">
                    <div className="breadcrumb-content">

                        <div className="breadcrumb-element">
                            <h2>{doc[0].d_name}</h2>
                        </div>
                        <div className="breadcrumb-element">
                            <Link to={"/archives/"}>
                                <p>Retour</p>
                            </Link>

                        </div>

                    </div>
                </div>

                <div className="arch-section">

                    <div className="arch-content">


                        <div className="document-content">

                            <div className="document-description">
                                <p>{doc[0].d_text}</p>

                            </div>
                            <h3>Fichiers</h3>
                            <hr />
                            {ifImage ? (
                                <div className="filelist">

                                  



                                    <ImageGallery items={images} />

                                </div>
                            ) : null}






                            {ifPdf ? (
                                <div className="filelist">

                                   

                                    {
                                        files.map(function (item, i) {
                                            return (
                                                <div className="file-content">
                                                    <div className="file-name">
                                                        <p>{item.d_name}</p>
                                                        <div className="file-description">
                                                        <p>{item.d_description}</p>
                                                        </div>
                                                    </div>

                                                   
                                                
                                                    <div className="file-button">
                                                        <a href={'https://arch1612.blob.core.windows.net/data/' + item.d_path} target="_blank" rel="noopener noreferrer">
                                                            <FontAwesomeIcon icon={faDownload} />
                                                        </a>
                                                    </div>
                                                   
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            ) : null}






                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Document;
