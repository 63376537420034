import React, { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import './css/style.css'

import { Link, useNavigate } from 'react-router-dom';




const LandingSmallCard = (props) => {

    const navigate = useNavigate();
    //const[backgroundImage, setBackgroundImage] = useState(`url(${props.image})`);

    function handleClick() {

        localStorage.setItem('catid', props.id);
        console.log(props.id);

        navigate('/archives');


    }


    return (
        <a href="#" onClick={handleClick}>
        <div className="landing-small-card" style={{ backgroundImage: `url(${props.image})` }} >
          
                <div className="landing-small-title">
                    <h2>{props.title}</h2>
                </div >

                      
        </div>
        </a>
    )
};

export default LandingSmallCard
